import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Trans } from 'gatsby-plugin-react-i18next'
import PropTypes from 'prop-types'
import CalendarCarousel from '../CalendarCarousel'
import { Header, Section } from './styles'

const CalendarTestimonials = ({ page, title }) => {
  const {
    forwardImg,
    backImg,
    customerOneImg,
    customerTwoImg,
    customerThreeImg,
    starRating,
    check,
  } = useStaticQuery(graphql`
    query CalendarTestimonialsQuery {
      customerOneImg: file(relativePath: { eq: "icons/avatar/avatar1.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      customerTwoImg: file(relativePath: { eq: "icons/avatar/avatar2.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      customerThreeImg: file(relativePath: { eq: "icons/avatar/avatar3.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      starRating: file(relativePath: { eq: "calendar/star-rating.webp" }) {
        childImageSharp {
          gatsbyImageData(width: 18, layout: FIXED, placeholder: NONE)
        }
      }
      check: file(relativePath: { eq: "calendar/orange-check.webp" }) {
        childImageSharp {
          gatsbyImageData(width: 18, layout: FIXED, placeholder: NONE)
        }
      }
      backImg: file(relativePath: { eq: "calendar/orange-left-arrow.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      forwardImg: file(relativePath: { eq: "calendar/orange-right-arrow.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  const customerImages = [
    customerTwoImg,
    customerOneImg,
    customerThreeImg,
    customerOneImg,
    customerThreeImg,
    customerTwoImg,
    customerOneImg,
    customerThreeImg,
    customerOneImg,
    customerThreeImg,
    customerTwoImg,
    customerOneImg,
    customerThreeImg,
  ]

  return (
    <Section>
      <Header isUnifiedHomepage={page === 'frame'}>
        <Trans>{title || `Families Rave About Skylight Calendar`}</Trans>
      </Header>
      <CalendarCarousel
        page={page}
        forwardImg={forwardImg}
        backImg={backImg}
        star={starRating}
        check={check}
        images={customerImages}
      />
    </Section>
  )
}

CalendarTestimonials.propTypes = {
  page: PropTypes.string,
  title: PropTypes.string,
}

export default CalendarTestimonials
