import React, { Component } from 'react'
import Slider from 'react-slick'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'

import { Card, LogoContainer, SliderContainer, Slide } from './styles'

class PressCarousel extends Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }

  handleLogoClick(index) {
    this.slider.slickGoTo(index)
  }

  next() {
    this.slider.slickNext()
  }

  previous() {
    this.slider.slickPrev()
  }

  render() {
    const { images, testimonials, bgColor, bottom, top } = this.props

    return (
      <SliderContainer bgColor={bgColor} bottom={bottom} top={top}>
        <Slider
          ref={(c) => (this.slider = c)}
          infinite
          speed={500}
          slidesToShow={4}
          slidesToScroll={1}
          initialSlide={0}
          autoplay
          autoplaySpeed={2500}
          responsive={[
            {
              breakpoint: 768,
              settings: {
                arrows: false,
                slidesToShow: 1,
                dots: true,
              },
            },
          ]}
        >
          {Object.keys(images).map((k, i) => (
            <Slide key={i} bgColor={bgColor}>
              <h1 className="text">{testimonials[i]}</h1>
              <Card>
                <LogoContainer
                  className="imgContainer"
                  id={k}
                  onClick={() => this.handleLogoClick(i)}
                >
                  <GatsbyImage
                    image={images[k].childImageSharp.gatsbyImageData}
                    alt="Happy Customer"
                  />
                </LogoContainer>
              </Card>
            </Slide>
          ))}
        </Slider>
      </SliderContainer>
    )
  }
}

PressCarousel.propTypes = {
  images: PropTypes.object,
  testimonials: PropTypes.array,
  bgColor: PropTypes.string,
  top: PropTypes.string,
  bottom: PropTypes.string,
}

export default PressCarousel
