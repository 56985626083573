import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const FeatureContainer = styled.div`
  padding: 40px 0px 80px;
  background-color: ${(props) => props.color};
  @media (max-width: ${breakpoints.xl}px) {
    padding: 40px 0px 57px;
  }
`
export const SecondaryHeader = styled.h3`
  font-family: 'P22MackinacProBook';
  font-size: 30px;
  line-height: 42px;
  letter-spacing: 0.15px;
  color: ${(props) => props.color || props.theme.blueDark};
  margin: 0px auto;
  font-weight: normal;
  display: ${(props) => (props.hideOnDesktop ? 'none' : 'block')};
  text-align: ${(props) => (props.textAlignRight ? 'right' : 'left')};
  @media (max-width: ${breakpoints.l}px) {
    font-size: 26px;
    line-height: 1.2;
    text-align: center;
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 26px;
    line-height: 1.19;
    letter-spacing: 0.13px;
  }
  @media (max-width: ${breakpoints.m}px) {
    display: ${(props) => (props.hideOnMobile ? 'none' : 'block')};
  }

  @media (max-width: 350px) {
    font-size: 22px;
  }
`
export const Text = styled.p`
  font-family: 'FilsonProBook';
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 26px;
  letter-spacing: 0.09px;
  text-align: ${(props) => (props.textAlignRight ? 'right' : 'left')};
  color: ${(props) => props.color || props.theme.grayDark};
  width: ${(props) => props.width || '50px'};
  @media (max-width: ${breakpoints.l}px) {
    text-align: center;
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.08px;
    text-align: center;
    margin: auto;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
    margin: auto;
  }
`
export const Header = styled.h2`
  font-family: 'P22MackinacProBook';
  font-size: 38px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 53px;
  letter-spacing: 0.19px;
  text-align: center;
  color: ${(props) => props.color || props.theme.blueDark};
  margin: 50px auto;
  max-width: 900px;
  @media (max-width: ${breakpoints.l}px) {
    margin: 30px auto;
    max-width: 90%;
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 30px;
    text-align: center;
    margin: 15px auto;
    line-height: 42px;
    letter-spacing: 0.15px;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 80%;
  }
`
export const Card = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 0px 30px;
  @media (max-width: ${breakpoints.l}px) {
    width: 100%;
    text-align: center;
  }
`
export const LandscapeImageCard = styled.div`
  width: 365px;
  margin: 0px 30px;
  position: relative;
  z-index: 2;
  @media (max-width: ${breakpoints.xl}px) {
    width: 320px;
    padding: auto 10px;
    margin: auto 20px;
  }
  @media (max-width: ${breakpoints.l}px) {
    margin: 30px auto;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin: 30px 10%;
    width: 250px;
  }
`
export const PortraitImageCard = styled.div`
  width: 320px;
  margin: 0px 30px;
  position: relative;
  z-index: 2;
  @media (max-width: ${breakpoints.xl}px) {
    width: 280px;
    padding: auto 10px;
    margin: auto 20px;
  }
  @media (max-width: ${breakpoints.l}px) {
    margin: 30px auto;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin: 30px 10%;
    width: 224px;
  }
`
export const TextContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media (max-width: ${breakpoints.l}px) {
    width: 100%;
    justify-content: center;
  }
  @media (max-width: ${breakpoints.s}px) {
    > div {
      width: 90%;
    }
    margin: auto;
  }
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(props) => (props.$start ? 'flex-start' : 'center')};
  align-items: stretch;
  margin: auto;
  @media (max-width: ${breakpoints.l}px) {
    flex-wrap: ${(props) => props.wrap || 'wrap'};
    margin-bottom: 0px;
  }
`
export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
export const ShapeContainerOne = styled.div`
  padding-top: 110px;
  width: 180px;
  height: 215px;
  z-index: 1;
  left: -70px;
  margin-left: 75px;
  margin-right: 20px;
  position: relative;
  @media (max-width: ${breakpoints.xl}px) {
    height: 315px;
    overflow: hidden;
  }
  @media (max-width: ${breakpoints.l}px) {
    display: none;
  }
`
export const PlusShapeContainerOne = styled(ShapeContainerOne)`
  padding-top: 80px;
  margin-left: 0px;
  min-width: 180px;
  @media (min-width: 1600px) {
    display: none;
  }
  @media (max-width: 1100px) {
    display: none;
  }
`
export const ShapeContainerTwo = styled.div`
  padding-top: ${(props) => (props.plus ? '190px' : '80px')};
  width: ${(props) => (props.plus ? '200px' : '280px')};
  height: 245px;
  z-index: 1;
  position: relative;
  padding-left: ${(props) => (props.plus ? '20px' : '0px')};
  @media (min-width: 1600px) {
    display: ${(props) => (props.plus ? 'none' : 'inline-block')};
  }
  @media (max-width: 1400px) {
    padding-top: ${(props) => (props.plus ? '140px' : '80px')};
  }
  @media (max-width: ${breakpoints.xl}px) {
    padding-left: 20px;
  }
  @media (max-width: 1100px) {
    display: ${(props) => (props.plus ? 'none' : 'inline-block')};
  }
  @media (max-width: ${breakpoints.l}px) {
    display: none;
  }
  > div > picture > img {
    object-fit: fill !important;
  }
`
export const ShapeContainerThree = styled.div`
  padding-top: ${(props) => (props.plus ? '80px' : '50px')};
  width: 95px;
  min-width: ${(props) => (props.plus ? '95px' : '0px')};
  height: 245px;
  z-index: 1;
  margin: auto 50px;
  left: -70px;
  position: relative;
  @media (min-width: 1600px) {
    display: ${(props) => (props.plus ? 'none' : 'inline-block')};
  }
  @media (max-width: 1400px) {
    padding-top: 50px;
  }
  @media (max-width: 1100px) {
    display: ${(props) => (props.plus ? 'none' : 'inline-block')};
  }
  @media (max-width: ${breakpoints.l}px) {
    display: none;
  }
`
export const ShapeContainerFour = styled(ShapeContainerTwo)`
  padding-top: 100px;
`
export const ShapeContainerFive = styled.div`
  padding-top: 120px;
  width: 160px;
  height: 370px;
  z-index: 1;
  left: -80px;
  position: relative;
  @media (max-width: ${breakpoints.l}px) {
    display: none;
  }
`
export const ShapeSixContainer = styled.div`
  padding-top: 100px;
  width: 182px;
  height: 245px;
  z-index: 1;
  @media (max-width: ${breakpoints.xl}px) {
    margin-left: 5%;
  }
  @media (max-width: ${breakpoints.l}px) {
    display: none;
  }
`
export const Video = styled.video`
  width: 49%;
  height: 100%;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    height: auto;
  }
  @media (max-width: ${breakpoints.l}px) {
    width: 100%;
    margin: 10px auto 40px;
  }
`
export const PlusImageCard = styled.div`
  position: relative;
  z-index: 10;
  width: 49%;
  min-width: 49%;
  @media (max-width: ${breakpoints.l}px) {
    width: 100%;
    margin: 40px auto 40px;
  }
`
export const PlusRowEnd = styled(Row)`
  justify-content: ${(props) => (props.$end ? 'flex-end' : 'center')};
`
export const DiscountDisclaimer = styled.p`
  font-family: 'FilsonProRegularItalic';
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  width: 90%;
  min-width: 90%;
  margin: auto;
  margin-top: 40px;
  padding: ${(props) => (props.padding ? '0px 0px 70px' : 'inherit')};
  @media (max-width: ${breakpoints.m}px) {
    margin-top: 20px;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding: ${(props) => (props.padding ? '0px 0px 35px' : 'inherit')};
  }
`
