import { BUY_LINK, CAL_BUY_LINK, FRAME_2_BUY_LINK } from '../../utils/constants'

export const HOMEPAGE_PRODUCT_DETAILS = [
  {
    name: 'Home',
    preheading: 'Skylight Calendar & Frame',
    heading: 'Keeping Families Connected',
    subheading:
      'Everyone’s on the same page (all the time!) with shareable photos, schedules and more.',
    link: `${BUY_LINK}`,
    link2: `${CAL_BUY_LINK}`,
    ctaText: 'Shop Frame',
    ctaText2: 'Shop Calendar',
    productId: '7073751498804',
    analyticsEvent: 'Clicked Buy Now Frame 2 Hero on Homepage CTA',
  },
]

export const FRAME_PRODUCT_DETAILS = [
  {
    name: 'Frame 2',
    preheading: 'Just released: The New Frame 2',
    heading: 'Perfect in Every Room',
    subheading:
      "Our best-selling smart photo frame just got even better. And with our new Snap Frames, you don't have to choose a color - you can have them all.",
    link: `${FRAME_2_BUY_LINK}`,
    ctaText: 'Buy Now',
    productId: '7073751498804',
    analyticsEvent: 'Clicked Buy Now Frame 2 Hero on Frame 2 CTA',
  },
]
