import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { createStorefrontApiClient } from '@shopify/storefront-api-client'
import Context from '../context/StoreContext'

import { useCartActions } from '../utils/Hooks/useCartActions.js'
import { useCartInitialization } from '../utils/Hooks/useCartInitialization.js'
import { useBannerManagement } from '../utils/Hooks/useBannerManagement.js'

const client = createStorefrontApiClient({
  storeDomain: `https://${process.env.GATSBY_SHOPIFY_STORE_NAME}`,
  apiVersion: '2024-07',
  publicAccessToken: `${process.env.GATSBY_SHOPIFY_ACCESS_TOKEN}`,
})

const ContextProvider = ({ children }) => {
  const initialStoreState = {
    client,
    adding: false,
    loading: true,
    cart: {},
    banner: { promoCode: '', channel: '', amount: '' },
  }
  const [store, updateStore] = useState(initialStoreState)

  const { initializeCart } = useCartInitialization(store, updateStore)
  const { updateBanner, checkAndApplyExistingBanner } = useBannerManagement(updateStore)
  const {
    addToCart,
    removeLineItem,
    updateLineItem,
    applyDiscount,
    removeDiscount,
    isUserBuyingPlus,
  } = useCartActions(store, updateStore)

  useEffect(() => {
    initializeCart()
    checkAndApplyExistingBanner()
  }, [initializeCart, checkAndApplyExistingBanner])

  return (
    <Context.Provider
      value={{
        store,
        addToCart,
        removeLineItem,
        updateLineItem,
        applyDiscount,
        removeDiscount,
        updateBanner,
        isUserBuyingPlus,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default ContextProvider

ContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
