import styled from 'styled-components'

export const BackorderContainer = styled.div`
  h3 {
    font-size: 22px;
    line-height: 32px;
    margin: 24px 0px 0px;
    color: ${(props) => props.theme[props.color]};
  }

  p {
    margin: 0 0 20px 0;
    color: ${(props) => props.theme[props.color]};
    font-family: FilsonProRegularItalic !important;
  }

  P:nth-of-type(2) {
    max-width: 550px;
    color: ${(props) => props.theme.textRegular};
    font-family: FilsonProBook !important;
  }

  a {
    color: ${(props) => props.theme.blueDark};
    text-decoration: underline;
  }
`
