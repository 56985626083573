import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'
import { GatsbyImage } from 'gatsby-plugin-image'

export const Section = styled.div`
  margin: auto;
  background-color: ${(props) => props.theme.cream};
  text-align: left;
  .slick-slide div {
    outline: none !important;
  }
  .slick-slide {
    padding: 0px 0px 20px;
    width: 100%;
    @media (max-width: ${breakpoints.l}px) {
      padding: 40px 0px 0px;
      text-align: center;
    }
    @media (max-width: ${breakpoints.s}px) {
      width: 100%;
      padding: 20px 0px 0px;
    }
    @media (max-width: 420px) {
      height: 660px;
    }
  }
  .slick-list {
    padding: 0 20% 0 0;
  }
  .slick-center .text {
    @media (max-width: ${breakpoints.s}px) {
      min-width: 320px;
    }
  }
  .slick-center .imgContainer {
    opacity: 1;
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 15px 15px;
  @media (max-width: ${breakpoints.l}px) {
    flex-wrap: wrap;
    height: auto;
    /* > div:first-child {
      margin-left: 0px;
      margin-right: 0px;
    }
    > div:nth-child(2) {
      margin-right: 0px;
    } */
  }
  @media (max-width: 746px) {
    margin: 10px 20px;
  }
  @media (max-width: 640px) {
    margin: 10px 10px;
  }
  @media (max-width: ${breakpoints.s}px) {
    height: auto;
  }
`

export const Text = styled.div`
  font-family: 'FilsonPro';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`
export const Card = styled.div`
  padding: 40px 45px;
  border-radius: 25px;
  box-shadow: 0px 0px 17px rgba(68, 68, 68, 0.15);
  text-align: left;
  position: relative;
  @media (max-width: 1300px) {
    padding: 40px 30px;
  }
  @media (max-width: ${breakpoints.s}px) {
    text-align: center;
    padding: 60px 20px 20px;
  }
  @media (max-width: 450px) {
    margin: 0px 40px;
  }
  @media (max-width: 400px) {
    margin: 0px 30px;
  }
`

export const Arrow = styled(GatsbyImage)`
  margin: 0px 12px 0px 12px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  width: 12px;
`

export const LogoContainer = styled.div`
  width: 80px;
  margin: auto;
  opacity: 0.5;
  @media (max-width: ${breakpoints.s}px) {
    width: 40px;
  }
`

export const SliderContainer = styled.div`
  position: relative;
  z-index: 1;
  .slick-center #card {
    p {
      opacity: 1;
      font-size: 24px;
      line-height: 36px;
      /* background-color: yellow; */
      font-family: 'FilsonPro';
      @media (max-width: ${breakpoints.m}px) {
        font-size: 16px;
        line-height: 24px;
        /* margin-top: 20px; */
      }
    }
    #customer {
      color: black;
      font-family: 'FilsonProBook';
      /* font-size: 12px;
      margin: 0px;
      line-height: 20px; */
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      @media (max-width: ${breakpoints.m}px) {
        font-size: 16px;
      }
    }
    #logo {
      opacity: 1;
    }
  }

  .slick-dots {
    position: initial;
    width: 100%;
    margin: auto;
    padding-top: 40px;
    li {
      position: initial;
    }
    @media (max-width: ${breakpoints.s}px) {
      padding-top: 0px;
    }
  }
  .slick-dots {
    .slick-active button {
      background-color: #bdbdbd;
    }
    button {
      background: rgba(242, 242, 242, 0.98);
      border-radius: 25px;
      width: 10px;
      height: 10px;
      ::before {
        display: none;
      }
    }
  }
`
export const LeftArrow = styled.div`
  overflow: hidden;
  position: absolute;
  top: 30%;
  left: 30px;
  z-index: 3;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  @media (max-width: 1100px) {
    top: 35%;
  }
  @media (max-width: ${breakpoints.s}px) {
    left: 0px;
    margin: 0px 0px;
  }
`
export const RightArrow = styled.div`
  position: absolute;
  top: 30%;
  right: 30px;
  z-index: 3;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  @media (max-width: 1100px) {
    top: 35%;
  }
  @media (max-width: ${breakpoints.s}px) {
    right: 0px;
    margin: 0px 0px;
  }
`

export const ReviewerDetails = styled.div`
  text-align: center;
`
export const CustomerDetail = styled.p`
  color: black;
  font-family: 'FilsonProBook';
  font-size: 12px;
  margin: 0px;
  @media (max-width: ${breakpoints.m}px) {
    font-size: 16px;
  }
`

export const SocialText = styled.p`
  text-align: center;
  opacity: 0.3;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0px 0px 50px;
  font-family: 'FilsonPro';
  color: black;
  @media (max-width: ${breakpoints.m}px) {
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;
    margin: 0px 0px 30px;
  }
`
export const Header = styled.p`
  font-family: 'FilsonPro';
  color: black;
  font-size: 40px;
  text-align: center;
  max-width: 90%;
  margin: 0px auto 40px;
  @media (max-width: 1100px) {
    font-size: 30px;
    line-height: 42px;
    letter-spacing: 0.15px;
    margin: 60px auto 40px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 24px;
    line-height: normal;
  }
`
export const DetailsContainer = styled.div`
  padding: 20px 0px 0px;

  @media (max-width: ${breakpoints.m}px) {
    padding: 20px 0px 30px;
  }
`
