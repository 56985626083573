import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'gatsby-plugin-react-i18next'
import {
  CustomizationOption,
  Subheader,
  FrameProductOptions,
  LimitedEdition,
} from '../FrameProductForm/styles'
import ColorSelectionItem from '../ColorSelectionItem'

const FrameColorSelection = ({
  FRAME_VARIANTS,
  LIMITED_EDITION_VARIANTS,
  selectedColor,
  handleColorSelection,
  contentfulCTAOverrides,
}) => (
  <CustomizationOption>
    <Subheader>
      <Trans>Select frame color</Trans>
    </Subheader>
    <FrameProductOptions>
      {FRAME_VARIANTS.map((variant) => {
        const isOutOfStock =
          contentfulCTAOverrides[`en_10_frame_${variant}`] === 'Out of Stock' ||
          contentfulCTAOverrides[`en_10_frame_${variant}`] === 'Sold Out'
        return (
          <ColorSelectionItem
            key={variant}
            variant={variant}
            onSelectColor={handleColorSelection}
            isOutOfStock={isOutOfStock}
            isSelected={selectedColor === variant}
          />
        )
      })}
    </FrameProductOptions>
    <LimitedEdition>
      <Trans>Limited Edition</Trans>
    </LimitedEdition>
    <FrameProductOptions>
      {LIMITED_EDITION_VARIANTS.map((variant) => {
        const isOutOfStock =
          contentfulCTAOverrides[`en_10_frame_${variant}`] === 'Out of Stock' ||
          contentfulCTAOverrides[`en_10_frame_${variant}`] === 'Sold Out'
        return (
          <ColorSelectionItem
            key={variant}
            variant={variant}
            onSelectColor={handleColorSelection}
            isOutOfStock={isOutOfStock}
            isSelected={selectedColor === variant}
          />
        )
      })}
    </FrameProductOptions>
  </CustomizationOption>
)

FrameColorSelection.propTypes = {
  FRAME_VARIANTS: PropTypes.array.isRequired,
  LIMITED_EDITION_VARIANTS: PropTypes.array.isRequired,
  selectedColor: PropTypes.string.isRequired,
  handleColorSelection: PropTypes.func.isRequired,
  contentfulCTAOverrides: PropTypes.object.isRequired,
}

export default FrameColorSelection
