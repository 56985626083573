import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import ConditionalCTA from 'src/components/ConditionalCTA'
import LoadingAnimation from 'src/components/LoadingAnimation'
import SEO from 'src/components/seo'
import { BuyNowLink } from 'src/utils/styles/global-styles'
import { BuyNowAnchor } from 'src/components/Hero/styles'
import {
  Text,
  Image,
  Header,
  Row,
  ImageContainer,
  Content,
  BlogHomeLink,
  ButtonContainer,
  Section,
  LinkContainer,
  BlogPostsContainer,
  BlogImage,
  BlogImageContainer,
  ContentDiv,
} from 'src/utils/styles/blogs'

class BlogPage extends React.Component {
  render() {
    const { pageContext } = this.props
    if (!pageContext?.fields) return <LoadingAnimation />
    const {
      slug,
      fields: { seo, hero, sections, indexing },
    } = pageContext
    return (
      <Section>
        <SEO title={seo.title} description={seo.meta_description} path={`/${slug}`} />
        {!indexing && (
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
        )}
        <Row>
          <LinkContainer>
            <BlogHomeLink to="/blog/">Blog</BlogHomeLink>
            <Header>{hero.headline}</Header>
          </LinkContainer>
          <ImageContainer>
            {sections[0] && sections[0].image && (
              <Image src={sections[0].image} alt={sections[0].image_title} />
            )}
          </ImageContainer>
        </Row>
        <BlogPostsContainer>
          {sections.map((section, i) => (
            <Content key={i}>
              {i % 2 !== 0 ? (
                <>
                  {sections[i].image ? (
                    <>
                      <BlogImageContainer>
                        <BlogImage src={sections[i].image} alt={sections[i].image_title} />
                      </BlogImageContainer>
                      <Text left dangerouslySetInnerHTML={{ __html: section.text }} />
                    </>
                  ) : (
                    <Text
                      width="80%"
                      margin="auto"
                      left
                      dangerouslySetInnerHTML={{ __html: section.text }}
                    />
                  )}
                </>
              ) : (
                <>
                  {sections[i].image ? (
                    <>
                      {i === 0 ? (
                        <Text
                          width="80%"
                          right
                          margin="auto"
                          dangerouslySetInnerHTML={{ __html: section.text }}
                        />
                      ) : (
                        <ContentDiv>
                          <Text right dangerouslySetInnerHTML={{ __html: section.text }} />
                          <BlogImageContainer>
                            <BlogImage src={sections[i].image} alt={sections[i].image_title} />
                          </BlogImageContainer>{' '}
                        </ContentDiv>
                      )}
                    </>
                  ) : (
                    <>
                      <Text
                        width="80%"
                        right
                        margin="auto"
                        dangerouslySetInnerHTML={{ __html: section.text }}
                      />
                    </>
                  )}
                </>
              )}
            </Content>
          ))}
          <ButtonContainer>
            <ConditionalCTA
              url={hero.cta_button_link}
              text={hero.cta_button_text}
              anchorComponent={BuyNowAnchor}
              linkComponent={BuyNowLink}
            />
          </ButtonContainer>
        </BlogPostsContainer>

        <br />
      </Section>
    )
  }
}

BlogPage.propTypes = {
  pageContext: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    fields: PropTypes.shape({
      seo: PropTypes.shape({
        title: PropTypes.string.isRequired,
        meta_description: PropTypes.string.isRequired,
      }),
      hero: PropTypes.shape({
        headline: PropTypes.string.isRequired,
        cta_button_link: PropTypes.string.isRequired,
        cta_button_text: PropTypes.string.isRequired,
      }),
      sections: PropTypes.arrayOf(
        PropTypes.shape({
          image: PropTypes.string,
          image_title: PropTypes.string,
          text: PropTypes.string.isRequired,
        })
      ),
      footer: PropTypes.shape({
        cta: PropTypes.string,
      }),
      indexing: PropTypes.string.isRequired,
    }),
  }),
}

export default BlogPage
