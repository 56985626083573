import React, { useContext, useState, useEffect } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'
import { currencyCode } from 'src/utils/constants'
import StoreContext from 'src/context/StoreContext'
import PropTypes from 'prop-types'
import {
  Heading,
  Text,
  Recommended,
  Close,
  Flexbox,
  Style,
  Price,
  CloseButton,
  ClosePopupButton,
  PopupBox,
  PopupOverlay,
  CtaContainer,
} from './styles'
import { useStyleConfig } from '../../templates/SnapFramePage/styleConfig'
import Analytics from '../../utils/Analytics'
import {
  StyleOptionsContainer,
  SelectionOption,
  StyleText,
  StyleDescription,
  ColorContainer,
} from '../../templates/CalendarProductPage/styles'
import { StyleSelectionContainer } from '../../templates/SnapFramePage/styles.js'
import ColorSelectionItem from '../ColorSelectionItem'
import AddToCartButton from '../AddToCartButton'

const Popup = ({ snapFrame, setDisplayPopup, cartLines, handleApplyDiscount }) => {
  const { closeIcon } = useStaticQuery(graphql`
    query PopupQuery {
      closeIcon: file(relativePath: { eq: "icons/close-icon.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 28, layout: FIXED, placeholder: NONE)
        }
      }
    }
  `)

  const [selectedStyle, setSelectedStyle] = useState(null)
  const [selectedColor, setSelectedColor] = useState(null)
  const [submitting, setSubmitting] = useState(false)

  const { addToCart } = useContext(StoreContext)
  const styleConfig = useStyleConfig()

  const popupCombos = {
    Wood: ['Walnut', 'Birch'],
    'Shadow Box': ['Aluminum'],
    Classic: ['Black'],
    Cover: ['Black'],
  }

  const isInCart = (styleKey, colorOption) =>
    cartLines.edges.some((lineItem) => {
      const productTitle = lineItem.node.merchandise.product.title
      return productTitle.includes(styleKey) && productTitle.includes(colorOption)
    })

  const getFirstAvailableOption = () => {
    for (const styleKey of Object.keys(popupCombos)) {
      for (const colorOption of popupCombos[styleKey]) {
        if (!isInCart(styleKey, colorOption)) {
          return { styleKey, colorOption }
        }
      }
    }
    return null
  }

  useEffect(() => {
    const firstAvailableOption = getFirstAvailableOption()
    if (firstAvailableOption) {
      setSelectedStyle(firstAvailableOption.styleKey)
      setSelectedColor(firstAvailableOption.colorOption)
    }
  }, [cartLines])

  if (!selectedStyle || !selectedColor) return null

  const key = `${selectedStyle} / ${selectedColor}`
  const variant = snapFrame.variants.find(({ title }) => title === key)
  const { title, price, shopifyId } = variant

  const handleAddToCart = async (variantId, quantity) => {
    const id = variantId.split('/').pop()
    const pushToDataLayer = () => {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          currencyCode,
          add: {
            products: [
              {
                id,
                name: title,
                category: 'Snap Frame',
                price,
                quantity,
              },
            ],
          },
        },
      })
    }

    if (!submitting) {
      setSubmitting(true)
      Analytics.track(`Added Snap Frame to Cart`)
      const items = [{ variantId, quantity }]
      const resp = await addToCart(items)
      if (resp && resp.error) {
        setSubmitting(false)
        alert(resp.error)
      } else {
        setDisplayPopup(false)
        pushToDataLayer(variantId, {
          frame: variant,
          name: title,
        })
        setSubmitting(false)
        handleApplyDiscount('SNAP10')
      }
    }
  }

  return (
    <PopupOverlay>
      <PopupBox>
        <CloseButton onClick={() => setDisplayPopup(false)}>
          <GatsbyImage image={closeIcon.childImageSharp.gatsbyImageData} alt="Close" />
        </CloseButton>
        <Heading>Bundle & Save with Extra Snap Frames</Heading>
        <Text>
          Why choose just one? <b>Save 10%</b> when you add extra Snap Frames to your Frame 2 order.
          Savings applied at checkout.
        </Text>
        <Recommended>Recommended for you</Recommended>
        <StyleSelectionContainer>
          <StyleOptionsContainer>
            {Object.keys(popupCombos).map((styleKey) => {
              const colors = popupCombos[styleKey]
              return colors.map((colorOption) => {
                if (isInCart(styleKey, colorOption)) {
                  return null
                }

                const image = styleConfig[styleKey].snapFrameIndividualImage[colorOption]
                return (
                  <SelectionOption
                    key={`${styleKey}-${colorOption}`}
                    className="style-option-box"
                    selected={selectedStyle === styleKey && selectedColor === colorOption}
                    onClick={() => {
                      setSelectedStyle(styleKey)
                      setSelectedColor(colorOption)
                    }}
                  >
                    <div className="color-option">
                      {image && (
                        <GatsbyImage
                          image={image.childImageSharp.gatsbyImageData}
                          alt={`${styleKey} ${colorOption}`}
                        />
                      )}
                    </div>
                    <Style>{styleKey}</Style>
                    <ColorContainer>
                      <StyleText> {colorOption}</StyleText>
                      <ColorSelectionItem
                        variant={colorOption}
                        isOutOfStock={false}
                        isSelected={selectedColor === colorOption}
                        smallRadio
                        onSelectColor={() => setSelectedColor(colorOption)}
                      />
                    </ColorContainer>
                    <Flexbox>
                      {(() => {
                        const snapPriceData = styleConfig[styleKey].snapPrices[colorOption]
                        const hasDiscount = snapPriceData.discountedPrice !== 'NONE'
                        return (
                          <>
                            <Price strikethough={hasDiscount}>${snapPriceData.originalPrice}</Price>
                            {hasDiscount && (
                              <Price discount>${snapPriceData.discountedPrice}</Price>
                            )}
                          </>
                        )
                      })()}
                    </Flexbox>
                    <StyleDescription>{styleConfig[styleKey].description}</StyleDescription>
                  </SelectionOption>
                )
              })
            })}
          </StyleOptionsContainer>
        </StyleSelectionContainer>
        <CtaContainer>
          <AddToCartButton
            label="Add to Cart"
            handleClick={() => handleAddToCart(shopifyId, 1)}
            oos={false}
            adding={submitting}
          />
        </CtaContainer>
        <ClosePopupButton type="button" onClick={() => setDisplayPopup(false)}>
          <Close>No thanks, continue to checkout</Close>
        </ClosePopupButton>
      </PopupBox>
    </PopupOverlay>
  )
}

export default Popup

Popup.propTypes = {
  snapFrame: PropTypes.object.isRequired,
  setDisplayPopup: PropTypes.func.isRequired,
  cartLines: PropTypes.object.isRequired,
  handleApplyDiscount: PropTypes.func.isRequired,
}
