import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const LogoContainer = styled.div`
  opacity: 0.2;
  max-width: 100%;
  display: inline-block;
  margin: auto;
  cursor: pointer;
  @media (max-width: ${breakpoints.m}px) {
    opacity: 1;
  }
`
export const SliderContainer = styled.div`
  background-color: ${(props) => (props.bgColor ? props.bgColor : props.theme.cream)};
  position: relative;
  z-index: 10;
  width: 100%;
  margin: ${(props) => (!props.isCalendarNY ? '50px auto 0px' : 'auto')};
  text-align: center;
  .slick-current .imgContainer {
    opacity: 1;
  }
  .slick-slide .text {
    display: none;
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    max-width: 80%;
    margin: auto;
    font-size: 32px;
    line-height: 40px;
  }
  .slick-slider .slick-track {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    max-width: 900px;
  }
  .slick-current .text {
    display: block;
  }
  #yahoo {
    width: 160px;
  }
  #wallStreet {
    width: 86px;
    margin: auto;
  }
  #buzzfeed {
    width: 200px;
  }
  #bump {
    width: 225px;
  }
  #truetrae {
    width: 295px;
  }
  #motherly {
    width: 125px;
  }
  #lifesavvy {
    width: 260px;
  }
  @media (max-width: ${breakpoints.m}px) {
    padding: 20px 0px 40px;
    .slick-slide {
      margin: auto;
    }
    .slick-slider {
      padding-bottom: ${(props) => (!props.isCalendarNY ? '50px' : '0px')};
    }
    .slick-slider .slick-track {
      display: block;
      max-width: none;
    }
    .slick-slide .text {
      max-width: 90%;
      display: block;
      position: relative;
      font-size: 20px;
      line-height: 32px;
      padding: 0px;
    }
    .slick-current .text {
      display: block;
      text-align: center;
    }
    .slick-current .imgContainer {
      opacity: 1;
    }
  }
  @media (max-width: ${breakpoints.s}px) {
    padding: 0px 0px 0px;
    .slick-slide .text {
      font-size: 24px;
      line-height: 32px;
    }
    #bump {
      width: 160px;
    }
    #truetrae {
      width: 210px;
    }
    #motherly {
      width: 90px;
    }
    #lifesavvy {
      width: 180px;
    }
  }
`
export const Card = styled.div`
  position: relative;
  padding-top: 100px;
  padding-bottom: 0px;
  display: flex;
  justify-content: center;
  margin: 0px 20px;
  display: inline-block;
  @media (max-width: ${breakpoints.xl}px) {
    display: block;
  }
  @media (max-width: ${breakpoints.m}px) {
    padding-top: 0px;
    width: 100%;
    margin: 10px 0px 0px;
  }
`
export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
`
export const Slide = styled.div`
  max-width: 90%;
  margin: auto;
  background-color: ${(props) => (props.bgColor ? props.bgColor : props.theme.cream)};
  border-radius: 25px;
`
