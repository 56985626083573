export const CAL_PLUS_FEATURES = [
  {
    name: 'Magic Import with Sidekick',
    checked: false,
  },
  {
    name: 'Photo & Video Screensaver',
    checked: false,
  },
  {
    name: 'Meal Planning',
    checked: false,
  },
  {
    name: 'Rewards',
    checked: false,
  },
  {
    name: 'Sync Online Calendars',
    checked: true,
  },
  {
    name: 'Chore Chart',
    checked: true,
  },
]
