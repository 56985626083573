import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { withTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import RedesignCalendarTestimonials from '../components/RedesignCalendarTestimonials'

import HomepageHero from '../components/HomepageHero/Index'
import SEO from '../components/seo'
import FramePress from '../components/FramePress'
import FAQs from '../components/FAQs'
import EffortlessToUse from '../components/EffortlessToUse'
import FreeReturns from '../components/FreeReturns'
import CalendarMaxFeatures from '../components/CalendarMaxFeatures'
import { HideOverflow } from '../utils/styles/global-styles'
import Analytics from '../utils/Analytics'
import FrameTable from '../components/FrameTable'
import GallerySlider from '../components/GallerySlider'
import SnapFrame from '../components/SnapFrame'
import { FRAME_PRODUCT_DETAILS } from '../components/HomepageHero/constants'

class Frame2Page extends React.Component {
  componentDidMount() {
    Analytics.track('Viewed Frame 2 Landing Page')
  }

  render() {
    const { t, data } = this.props
    const { frame2Image } = data

    return (
      <>
        <SEO
          title={t('Skylight Frame 2 | Skylight Frame')}
          description={t(
            'Interchangeable frames, higher resolution screen, more onboard storage and free videos and captions. Give The Perfect Gift — Shop The Smart Digital Frame 2'
          )}
          imagePath={frame2Image.childImageSharp.gatsbyImageData.images.fallback.src}
          path="/frame-2/"
        />
        <Helmet>
          <meta charset="UTF-8" />
          <link rel="canonical" href="https://www.skylightframe.com/frame-2" />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css"
          />
        </Helmet>
        <HideOverflow>
          <HomepageHero singleSlide slideContent={FRAME_PRODUCT_DETAILS} noDualCTA isFrame2 />
          <FramePress frame2 />
          <EffortlessToUse title={t('Easy to Use. Easy to Love.')} />
          <CalendarMaxFeatures page="frame" cream />
          <FrameTable />
          <GallerySlider />
          <HideOverflow>
            <RedesignCalendarTestimonials frame />
          </HideOverflow>
          <SnapFrame />
          <FreeReturns />
          <FAQs page="frame" />
        </HideOverflow>
      </>
    )
  }
}

Frame2Page.propTypes = {
  t: PropTypes.func,
  data: PropTypes.object.isRequired,
}

export default withTranslation()(Frame2Page)

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["common", "frame"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    frame2Image: file(relativePath: { eq: "homepage/baby-dog-in-wood-frame-mobile.webp" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    countdown: allContentfulCountdownTimer {
      edges {
        node {
          de_countdown
          en_au_countdown
          en_ca_countdown
          en_countdown
          en_gb_countdown
          en_eu_countdown
          es_countdown
          fr_countdown
          ie_countdown
          it_countdown
          nl_countdown
        }
      }
    }
  }
`
