import styled from 'styled-components'
import { breakpoints, MediumHeader } from 'src/utils/styles/global-styles'
import { GatsbyImage } from 'gatsby-plugin-image'

export const CalendarSyncsSection = styled.div`
  background-color: ${(props) => (props.bgColor ? props.bgColor : props.theme.cream)};
  padding: ${(props) => (props.isCalProduct ? '0px' : '40px 0px')};
  position: relative;
  text-align: center;
  @media (max-width: ${breakpoints.s}px) {
    padding: ${(props) => (props.isCalProduct ? '30px 0px 0px' : '0px')};
  }
`
export const Container = styled.div`
  max-width: 600px;
  margin: auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  @media (max-width: ${breakpoints.s}px) {
    justify-content: ${(props) => (props.isCalProduct ? 'space-between' : 'space-around')};
    margin-top: 16px;
  }
`
export const Card = styled.div`
  max-width: 100px;
  padding: ${(props) => (props.isCalProduct ? '10px 0px' : '10px')};
  @media (max-width: ${breakpoints.s}px) {
    padding: 5px;
  }
`
export const Text = styled.h3`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 10px;
    line-height: 15px;
    margin-top: 5px;
  }
`
export const Icon = styled(GatsbyImage)`
  width: 75px;
  @media (max-width: 1450px) {
    width: ${(props) => (props.isCalProduct ? '68px' : '75px')};
  }
  @media (max-width: 1250px) {
    width: ${(props) => (props.isCalProduct ? '50px' : '75px')};
  }
  @media (max-width: ${breakpoints.l}px) {
    width: 75px;
  }
  @media (max-width: ${breakpoints.m}px) {
    width: 55px;
  }
  @media (max-width: 400px) {
    width: 50px;
  }
  @media (max-width: 450px) {
    width: 45px;
  }
`
export const Header = styled(MediumHeader)`
  display: block;
  font-family: 'filsonProBook';
  margin-top: 50px;
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 26px;
  @media (max-width: ${breakpoints.m}px) {
    margin-top: 40px;
    margin-bottom: 30px;
    font-size: 16px;
  }
  @media (max-width: ${breakpoints.s}px) {
    max-width: 100%;
    margin: 20px auto;
  }
`
export const CalSyncsContainer = styled.div`
  @media (max-width: ${breakpoints.s}px) {
    padding: 32px 0px 16px;
    max-width: 90%;
    margin: auto;
  }
  @media (max-width: 385px) {
    padding: 40px 0px 16px;
  }
`
