import 'whatwg-fetch'
import { IS_FOREIGN_COUNTRY } from './src/utils/constants'

export const onRouteUpdate = ({ location }) => {
  if (IS_FOREIGN_COUNTRY) {
    const scriptId = 'trustpilot-widget-script'
    const targetStrings = ['the-skylight-frame', 'the-skylight-calendar-15', 'skylight-plus']
    // Function to load the script and initialize the widget
    const loadScript = () => {
      // Remove existing script if it exists
      const existingScript = document.getElementById(scriptId)
      if (existingScript) {
        existingScript.remove()
      }
      // Create a new script element
      const script = document.createElement('script')
      script.id = scriptId
      script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
      script.async = true
      script.onload = () => {
        // Re-initialize the Trustpilot widget after the script loads
        if (window.Trustpilot) {
          window.Trustpilot.loadFromElement(document.querySelector('.trustpilot-widget'), true)
        }
      }
      document.body.appendChild(script)
    }
    if (targetStrings.some((str) => location.pathname.includes(str))) {
      loadScript()
    }
  }
}
