import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import sageDesktop from 'src/gifs/sage-snap-frame-desktop.gif'
import sageMobile from 'src/gifs/sage-snap-frame-mobile.gif'

import {
  SectionWrapper,
  BackgroundImage,
  Gallery,
  Grid,
  TwoSpanTile,
  FullSpanTile,
  Tile,
  ProductImage,
  SectionHeader,
  HolidayText,
  LightenImage,
  LightenImageContainer,
  BestSeller,
  New,
} from './styles'
import {
  Title,
  LightenLoadGridContainer,
  LightenCard,
  SectionTitle,
  TitleContainer,
  CardContent,
  CardTitle,
  SectionContainer,
  DesktopImage,
  MobileImage,
  SpanGifContainer,
  GifContentContainer,
  ProductSectionTitle,
  GiftDetail,
  GifImageContainer,
  ProductLabel,
  Label,
  LightenLoadContent,
} from '../styles'
import GiftButtons from '../GiftButtons'
import GiftCarousel from '../GiftCarousel'

const LightenTheLoad = () => {
  const {
    backgroundMobile,
    delivery,
    customerService,
    truck,
    background,
    redbackground,
    backgroundTree,
    holidayDesktopTestimonial,
    holidayTwoSquare,
    woodBirch,
    woodWalnut,
    classicSage,
    classicBlack,
    classicLagoon,
    classicWhite,
    coverBlack,
    coverWhite,
    shadowBoxAluminum,
    shadowBoxCharcoal,
    frameBlack,
    frameWhite,
    frameSilver,
    frameGold,
    framePoppy,
    bestGift,
    frame15,
    calViewOne,
    calViewTwo,
    calViewThree,
    calViewFour,
    calendar15,
    calendar10,
    testimonialDesktopBg,
    testimonialMobileBg,
    bestSeller,
  } = useStaticQuery(graphql`
    query LightenTheLoadQuery {
      delivery: file(relativePath: { eq: "giftGuide/package.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      customerService: file(relativePath: { eq: "giftGuide/customerService.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      truck: file(relativePath: { eq: "giftGuide/truck.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      background: file(relativePath: { eq: "giftGuide/lighten-bg.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      redbackground: file(relativePath: { eq: "giftGuide/holiday-desktop-background.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      backgroundTree: file(relativePath: { eq: "giftGuide/desktop-tree.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      backgroundMobile: file(relativePath: { eq: "giftGuide/background-mobile-bg.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      holidayDesktopTestimonial: file(
        relativePath: { eq: "giftGuide/holiday-desktop-testimonial.webp" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      holidayTwoSquare: file(relativePath: { eq: "giftGuide/holiday-2-square.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      woodBirch: file(relativePath: { eq: "giftGuide/frame-2-wood-birch.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      woodWalnut: file(relativePath: { eq: "giftGuide/frame-2-wood-walnut.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      classicSage: file(relativePath: { eq: "giftGuide/frame-2-classic-sage.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      classicBlack: file(relativePath: { eq: "giftGuide/frame-2-classic-black.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      classicLagoon: file(relativePath: { eq: "giftGuide/frame-2-classic-lagoon.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      classicWhite: file(relativePath: { eq: "giftGuide/frame-2-classic-white.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      coverBlack: file(relativePath: { eq: "giftGuide/frame-2-cover-black.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      coverWhite: file(relativePath: { eq: "giftGuide/frame-2-cover-white.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      shadowBoxAluminum: file(relativePath: { eq: "giftGuide/frame-2-shadow-box-aluminum.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      shadowBoxCharcoal: file(relativePath: { eq: "giftGuide/frame-2-shadow-box-charcoal.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      frameBlack: file(relativePath: { eq: "giftGuide/frame-black.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      frameWhite: file(relativePath: { eq: "giftGuide/frame-white.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      frameSilver: file(relativePath: { eq: "giftGuide/frame-silver.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      frameGold: file(relativePath: { eq: "giftGuide/frame-gold.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      framePoppy: file(relativePath: { eq: "giftGuide/frame-poppy.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      bestGift: file(relativePath: { eq: "giftGuide/frame-best-gift.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      frame15: file(relativePath: { eq: "giftGuide/frame-15-bg.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      calViewOne: file(relativePath: { eq: "giftGuide/cal-view-1.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      calViewTwo: file(relativePath: { eq: "giftGuide/cal-view-2.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      calViewThree: file(relativePath: { eq: "giftGuide/cal-view-3.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      calViewFour: file(relativePath: { eq: "giftGuide/cal-view-4.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      calendar15: file(relativePath: { eq: "giftGuide/calendar-15-bg.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      calendar10: file(relativePath: { eq: "giftGuide/calendar-10-bg.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      testimonialDesktopBg: file(relativePath: { eq: "giftGuide/testimonial-desktop-bg.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      testimonialMobileBg: file(relativePath: { eq: "giftGuide/testimonial-mobile-bg.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      bestSeller: file(relativePath: { eq: "giftGuide/badges/best-seller.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
    }
  `)

  const features = [
    {
      title: 'Hassle-free Returns',
      detail: '120 Day Free Return Guarantee',
      image: delivery,
      key: 'delivery',
    },
    {
      title: '1-1 Personalized Support',
      detail: 'Contact Us',
      link: 'https://skylight.zendesk.com/hc/en-us/categories/360001842931-Contact-Us',
      image: customerService,
      key: 'contact',
    },
    {
      title: 'Gift Essentials Exclusive!',
      // detail: 'Free Shipping with Code XXXXX',
      detail: '',
      image: truck,
      key: 'truck',
    },
  ]

  const frame2Products = [
    {
      'Frame 2': [
        { title: 'Wood / Birch', image: woodBirch, link: 'wood-birch' },
        { title: 'Wood / Walnut', image: woodWalnut, link: 'wood-walnut' },
        { title: 'Classic / Sage', image: classicSage, link: 'classic-sage' },
        { title: 'Classic / Black', image: classicBlack, link: 'classic-black' },
        { title: '', image: '', link: '' },
        { title: 'Classic / Lagoon', image: classicLagoon, link: 'classic-lagoon' },
        { title: 'Classic / White', image: classicWhite, link: 'classic-white' },
        { title: 'Cover / Black', image: coverBlack, link: 'cover-black' },
        { title: 'Cover / White', image: coverWhite, link: 'cover-white' },
        { title: 'Shadow Box / Aluminum', image: shadowBoxAluminum, link: 'shadow-box-aluminum' },
        { title: 'Shadow Box / Charcoal', image: shadowBoxCharcoal, link: 'shadow-box-charcoal' },
      ],
    },
    {
      Frame: [
        { title: 'Black', image: frameBlack, link: '/' },
        { title: 'White', image: frameWhite, link: '-white' },
        { title: 'Gold', image: frameGold, link: '-gold' },
        { title: 'Silver', image: frameSilver, link: '-silver' },
        { title: 'Poppy', image: framePoppy, link: '-poppy' },
        { title: '', image: bestGift, link: '' },
      ],
    },
    {
      CalendarMax: [
        {
          title: 'Shadow Box / Aluminum',
          image: calViewOne,
          link: 'skylight-calendar-max',
        },
        {
          title: 'Shadow Box / Charcoal',
          image: calViewTwo,
          link: 'skylight-calendar-max-shadow-box-charcoal',
        },
        {
          title: 'Classic / Black',
          image: calViewThree,
          link: 'skylight-calendar-max-classic-black',
        },
        {
          title: 'Classic / White',
          image: calViewFour,
          link: 'skylight-calendar-max-classic-white',
        },
        { title: '', image: holidayTwoSquare, link: '' },
      ],
    },
  ]

  return (
    <>
      <SectionWrapper>
        <DesktopImage image={background.childImageSharp.gatsbyImageData} />
        <MobileImage image={backgroundMobile.childImageSharp.gatsbyImageData} />
        <SectionContainer>
          <div className="content">
            <LightenLoadContent>
              <SectionTitle>Lighten The Load</SectionTitle>
              <TitleContainer>
                <Title>Let Skylight help with the holidays.</Title>
              </TitleContainer>
              <LightenLoadGridContainer>
                {features.map((feature, index) => (
                  <LightenCard key={index} transparent>
                    <CardContent className="lighten">
                      <LightenImageContainer>
                        <LightenImage
                          className={`${feature.key}`}
                          image={feature.image.childImageSharp.gatsbyImageData}
                          alt={feature.title}
                        />
                      </LightenImageContainer>
                      <div>
                        <CardTitle>{feature.title}</CardTitle>
                        {feature.link ? (
                          <a href={feature.link} target="_blank" rel="noopener noreferrer">
                            <Label>{feature.detail}</Label>
                          </a>
                        ) : (
                          <Label>{feature.detail}</Label>
                        )}
                      </div>
                    </CardContent>
                  </LightenCard>
                ))}
              </LightenLoadGridContainer>
            </LightenLoadContent>
          </div>
        </SectionContainer>
      </SectionWrapper>
      <SectionWrapper desktopOnly>
        <BackgroundImage image={backgroundTree.childImageSharp.gatsbyImageData} />
        <div className="content">
          <SectionTitle>Give the Gift of Family Connection</SectionTitle>
          <GiftButtons isMobile={false} />
        </div>
      </SectionWrapper>
      <GiftButtons isMobile />
      <SectionWrapper>
        <BackgroundImage image={redbackground.childImageSharp.gatsbyImageData} />
        <SectionContainer>
          <div className="content" id="frame-essentials">
            <SectionTitle>Find The Perfect Frame</SectionTitle>
            <TitleContainer>
              <Title>Enjoy easy setup and personalization of the touchscreen frame.</Title>
            </TitleContainer>
            <GiftCarousel frame />
            <Gallery id="frame-2">
              <SectionHeader>
                <New>New!</New>Frame 2
              </SectionHeader>
              <Grid>
                {frame2Products[0]['Frame 2'].map((product, index) => {
                  if (index === 4) {
                    return (
                      <TwoSpanTile key={product.title}>
                        <SpanGifContainer>
                          <GifContentContainer>
                            <ProductSectionTitle>Snap Frames</ProductSectionTitle>
                            <GiftDetail>Swap styles in a sec.</GiftDetail>
                          </GifContentContainer>
                          <GifImageContainer>
                            <img
                              src={sageDesktop}
                              alt="Frame 2 Snap Frame transition"
                              width="100%"
                            />
                            <img
                              src={sageMobile}
                              alt="Frame 2 Snap Frame transition"
                              width="100%"
                            />
                          </GifImageContainer>
                        </SpanGifContainer>
                      </TwoSpanTile>
                    )
                  }
                  return (
                    <Link to={`/products/skylight-frame-2-${product.link}`}>
                      <Tile key={product.title}>
                        <ProductImage>
                          <GatsbyImage image={product.image.childImageSharp.gatsbyImageData} />
                        </ProductImage>{' '}
                        <ProductLabel>{product.title}</ProductLabel>
                      </Tile>
                    </Link>
                  )
                })}
                <FullSpanTile>
                  <GatsbyImage image={holidayDesktopTestimonial.childImageSharp.gatsbyImageData} />
                  <HolidayText short>
                    “Skylight Frame was the best gift my grandmother has ever received.”
                  </HolidayText>
                </FullSpanTile>
              </Grid>
              <SectionHeader id="frame">10" Frame</SectionHeader>
              <Grid>
                {frame2Products[1].Frame.map((product) => {
                  const productLink = product.link
                    ? `/products/the-skylight-frame${product.link}`
                    : null
                  const TileContent = (
                    <Tile key={product.title}>
                      {product.title === 'Black' && (
                        <BestSeller>
                          <GatsbyImage image={bestSeller.childImageSharp.gatsbyImageData} />
                        </BestSeller>
                      )}
                      <ProductImage padding>
                        <GatsbyImage image={product.image.childImageSharp.gatsbyImageData} />
                      </ProductImage>
                      <ProductLabel>{product.title}</ProductLabel>
                    </Tile>
                  )
                  return productLink ? (
                    <Link to={productLink} key={product.title}>
                      {TileContent}
                    </Link>
                  ) : (
                    TileContent
                  )
                })}
              </Grid>
              <SectionHeader>15" Frame</SectionHeader>
              <Link to="/products/the-skylight-frame-15">
                <Grid>
                  <FullSpanTile>
                    <GatsbyImage image={frame15.childImageSharp.gatsbyImageData} />
                  </FullSpanTile>
                </Grid>
              </Link>
              <SectionTitle id="calendar-essentials">Manage It All With Calendar</SectionTitle>
              <Title>Hassle less. Holiday more.</Title>
              <GiftCarousel />
              <SectionHeader>Calendar Max</SectionHeader>
              <Grid>
                {frame2Products[2].CalendarMax.map((product, index) => {
                  if (index === 4) {
                    return (
                      <TwoSpanTile key={product.title}>
                        <GatsbyImage image={holidayTwoSquare.childImageSharp.gatsbyImageData} />
                        <HolidayText>
                          “I received Skylight Calendar as a Christmas gift last year and don't know
                          how we managed without it!”
                        </HolidayText>
                      </TwoSpanTile>
                    )
                  }
                  return (
                    <Link to={`/products/${product.link}`}>
                      <Tile key={product.title}>
                        <ProductImage>
                          <GatsbyImage image={product.image.childImageSharp.gatsbyImageData} />
                        </ProductImage>{' '}
                        <ProductLabel padding>{product.title}</ProductLabel>
                      </Tile>
                    </Link>
                  )
                })}
              </Grid>
              <SectionHeader id="calendar-15">15" Calendar</SectionHeader>
              <Link to="/products/skylight-calendar">
                <Grid full>
                  <FullSpanTile>
                    <GatsbyImage image={calendar15.childImageSharp.gatsbyImageData} />
                    <BestSeller cal>
                      <GatsbyImage image={bestSeller.childImageSharp.gatsbyImageData} />
                    </BestSeller>
                  </FullSpanTile>
                </Grid>
              </Link>
              <SectionHeader>10" Calendar</SectionHeader>
              <Link to="/products/the-skylight-calendar-pro-149">
                <Grid full>
                  <FullSpanTile>
                    <GatsbyImage image={calendar10.childImageSharp.gatsbyImageData} />
                  </FullSpanTile>
                </Grid>
              </Link>
            </Gallery>
          </div>
        </SectionContainer>
      </SectionWrapper>
      <SectionWrapper>
        <DesktopImage image={testimonialDesktopBg.childImageSharp.gatsbyImageData} />
        <MobileImage image={testimonialMobileBg.childImageSharp.gatsbyImageData} />
        <div className="content">
          <SectionContainer>
            <GiftCarousel testimonials />
          </SectionContainer>
          <GiftButtons isMobile={false} />
          <GiftButtons isMobile />
        </div>
      </SectionWrapper>
    </>
  )
}

export default LightenTheLoad
