import React from 'react'
import styled from 'styled-components'
import { ContentContainer, BigHeader } from 'src/utils/styles/global-styles'
import SEO from '../../components/seo'

class ReferAFriend extends React.Component {
  componentDidMount() {
    // eslint-disable-next-line no-unused-vars
    const referralCandyScript = !(function (d, s, id) {
      let js
      const fjs = d.getElementsByTagName(s)[0]
      if (!d.getElementById(id)) {
        js = d.createElement(s)
        js.id = id
        js.src = '//portal.referralcandy.com/assets/widgets/refcandy-candyjar.js'
        fjs.parentNode.insertBefore(js, fjs)
      }
    })(document, 'script', 'refcandy-candyjar-js')
  }

  render() {
    return (
      <>
        <SEO
          title="Refer a Friend Discount | Skylight"
          description="Refer a friend, Get $20 and give $20off a Skylight Calendar"
        />
        <ContentContainer>
          <Header>Refer a friend</Header>
          <ReferralCandyForm
            title="refer a friend"
            id="refcandy-candyjar"
            data-id="o3u4teoed93wc2v7syy54b3kz"
            data-width="100%"
            frameBorder="0"
          />
        </ContentContainer>
      </>
    )
  }
}

export default ReferAFriend

export const Header = styled(BigHeader)`
  margin: 3rem 0px 1rem 0px;
  text-align: center;
`
export const Message = styled.p`
  width: 500px;
  max-width: 90%;
`
export const ReferralCandyForm = styled.iframe`
  height: 100%;
  height: 1350px;
  @media (max-width: 800px) {
    height: 1100px;
  }
`
